import 'owl.carousel';

export default {
    init() {
        console.log('common');
    },
    finalize() {

        (function($) {
            'use strict';

            $(document).ready(function() {
                eventListener();

                $('.owl-carousel').owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<i class="blog-prev-button fas fa-chevron-left"></i>',
                        '<i class="blog-next-button fas fa-chevron-right"></i>'
                    ],
                    items: 1,
                    autoplay: true,
                    autoplayHoverPause: true,
                    autoplaySpeed: 1000
                });
            });

            // listens for click events
            function eventListener() {
                // $('a').on('click', smoothscroll);
                $('.mobileNavBtn').on('click', toggleMobileMenu);
                $('.mobileNavClose').on('click', closeMobileMenu);
                $(".header-container").on("click", showText);
                $('.overlay-menu a').on('click', closeMobileMenu);
            }

            // function smoothscroll(e) {
            //     if ( this.hash !== "" ) {
            //         // Prevent default anchor click behavior
            //         e.preventDefault();
            //
            //         // Store hash
            //         let hash = this.hash;
            //
            //         // Using jQuery's animate() method to add smooth page scroll
            //         $('html, body').animate({
            //             scrollTop: $( hash ).offset().top
            //         }, 800, function(){
            //
            //             // Add hash (#) to URL when done scrolling (default click behavior)
            //             window.location.hash = hash;
            //         });
            //     } // End if
            // }

            // toggles mobile menu visibility
            function toggleMobileMenu() {
                console.log('mobilenavbtn clicked')
                const s1 = new TimelineMax();
                let $logo = $('#mobileNav');
                s1.to($logo, 1, {x:0,y:-200});
                $('#overlay').toggleClass('open');
                $('body').addClass('scroll-y-hidden');
            }

            function closeMobileMenu() {
                const s1 = new TimelineMax();
                let $logo = $('#mobileNav');
                s1.to($logo, 1, {x:0,y:0});
                $('#overlay').toggleClass('open');
                $('body').removeClass('scroll-y-hidden');

            }

            function showText() {
                console.log("plus clicked");
                $(this).next(".hidden-item").slideToggle("slow");
            }



        })( jQuery );
    },
};
