export default {
  init() {
    console.log('home');
  },
  finalize() {

    (function($) {
      'use strict';

      $(window).load(function() {

      });

    })( jQuery );

  },
};
